import { type Feature } from 'geojson'
import { atom } from 'jotai'
import { atomWithLocation } from 'jotai-location'
import { atomWithStorage } from 'jotai/utils'
import { type Sort } from 'mongodb'
import { type LngLatBounds, type MapRef } from 'react-map-gl'
import { DataLayerEntry, type Listing } from '~/models'

export const aMapRef = atom<MapRef | null>(null)

export const aMapStyle = atomWithStorage<'street' | 'satellite'>(
  'mapStyle',
  'street'
)

export const aMapViewState = atom<{
  bounds: LngLatBounds
  lat: number
  lng: number
  zoom: number
} | null>(null)

export type SelectedListing = {
  _id: string
  slug: string
  status: string
  type: string
  propertyTypes: string[]
  location: {
    coordinates: number[]
  }
}

export const aSelectedListing = atom<SelectedListing | Listing | null>(null)

export type HoverFeature = {
  address?: string
  lat: number
  lng: number
  name: string
  propertyTypes: string
  status: string
  type: string
  _id: string
}
export const aHoverFeature = atom<HoverFeature | null>(null)
export const aMarkLocation = atom<boolean>(false)
export const aIsMapEasing = atom<boolean>(false)

export const aMapCurrentCount = atom<number | null>(null)
export const aCheckedListings = atom<string[]>([])

export const aDrawnFeature = atom<Feature | null>(null)
// export const aCheckedListings = atomWithStorage<string[]>('checkedListings', [])
// export const aDrawnFeature = atomWithStorage<Feature | null>(
//   'drawFeature',
//   null
// )
export const aDrawMode = atom<string | null>(null)

export const aParcelDataVisible = atomWithStorage<boolean>(
  'parcelDataVisible',
  true
)
export const aVisibleLayers = atom<string[]>([])
export const aHoverDataLayerEntry = atom<DataLayerEntry | null>(null)

export const aVisibleGeographies = atomWithStorage<string[]>(
  'visibleGeographies',
  []
)

// Internal url tracking. Used by aMapFilters to get/set url params
const aMapLocation = atomWithLocation()

export type MapFiltersType = {
  lat?: string
  lng?: string
  z?: string
  b?: string
  p?: string
  search?: string
  types?: string[]
  statuses?: string[]
  propertyTypes?: string[]
  propertySubtypes?: string[]
  submarkets?: string[]
  zoning?: string[]
  prices?: (number | undefined)[]
  caps?: (number | undefined)[]
  rates?: (number | undefined)[]
  bsf?: (number | undefined)[]
  sf?: (number | undefined)[]
  acres?: (number | undefined)[]
  companies?: string[]
  brokers?: string[]
  created?: string
  transacted?: string
  sort?: string
  postalCodes?: string[]
  cities?: string[]
  counties?: string[]
}

export const DEFAULT_FILTERS: MapFiltersType = {
  types: ['sale', 'lease'],
  statuses: ['active', 'pending'],
}

export const aMapFilters = atom(
  (get): MapFiltersType => {
    const filters: { [key: string]: any } = {}
    let searchParams =
      get(aMapLocation).searchParams && get(aMapLocation).searchParams!.size > 0
        ? get(aMapLocation).searchParams
        : null
    if (!searchParams && typeof window !== 'undefined') {
      searchParams = new URLSearchParams(window.location.search)
    }
    if (searchParams) {
      for (const [key, value] of searchParams.entries()) {
        if (['lat', 'lng', 'z', 'b', 'p'].includes(key)) {
          if (value != '0') {
            filters[key] = value
          }
        } else if (value) {
          if (
            key === 'created' ||
            key === 'transacted' ||
            key === 'sort' ||
            key === 'search'
          ) {
            filters[key] = value
          } else {
            filters[key] = value.split('_').map((v) => {
              if (
                key === 'companies' ||
                key === 'brokers' ||
                key === 'postalCodes' ||
                key === 'propertySubtypes'
              ) {
                return v
              }
              const parsed = parseFloat(v)
              return Number.isNaN(parsed) ? v : parsed
            })
          }
        }
      }
      return {
        ...DEFAULT_FILTERS,
        ...filters,
      }
    }
    return DEFAULT_FILTERS
  },
  (get, set, filters: { [key: string]: any }) => {
    const params = getParamsFromMapFilters(filters)
    const selectedListing = get(aSelectedListing)
    set(aMapLocation, {
      pathname: selectedListing ? `/${selectedListing.slug}` : '/',
      searchParams: new URLSearchParams(params),
    })
  }
)

export function getParamsFromMapFilters(filters: { [key: string]: any }) {
  const params: [string, string][] = []
  // Compare filters to default and exclude if the same
  Object.keys(filters).forEach((key) => {
    if (filters[key] && !['lat', 'lng', 'z', 'b', 'p'].includes(key)) {
      if (Array.isArray(filters[key])) {
        if (filters[key].filter((v?: any) => v).length > 0) {
          params.push([key, filters[key].join('_')])
        }
      } else {
        params.push([key, filters[key]])
      }
    }
  })
  if (typeof filters.b !== 'undefined' && filters.b != 0) {
    params.unshift(['b', filters.b])
  }
  if (typeof filters.p !== 'undefined' && filters.p != 0) {
    params.unshift(['p', filters.p])
  }
  if (typeof filters.z !== 'undefined') {
    params.unshift(['z', filters.z])
  }
  if (
    typeof filters.lat !== 'undefined' &&
    typeof filters.lng !== 'undefined'
  ) {
    params.unshift(['lat', filters.lat])
    params.unshift(['lng', filters.lng])
  }
  return params
}

const getFindRange = (minValue?: number, maxValue?: number) => {
  const f: { $gte?: number; $lte?: number } = {}
  if (minValue) {
    f['$gte'] = minValue
  }
  if (maxValue) {
    f['$lte'] = maxValue
  }
  return Object.keys(f).length === 0 ? undefined : f
}

type MapFiltersSaleFind = {
  type: { $in: string[] }
  'filters.price'?: { $gte?: number; $lte?: number }
  'filters.capRate'?: { $gte?: number; $lte?: number }
}

type MapFiltersLeaseRateFind = {
  $or?: {
    $and?: {
      'filters.minRate'?: { $gte?: number; $lte?: number }
      'filters.maxRate'?: { $gte?: number; $lte?: number }

      'filters.minRateHistoric'?: { $gte?: number; $lte?: number }
      'filters.maxRateHistoric'?: { $gte?: number; $lte?: number }
    }[]
    // 'filters.minRate'?: { $gte?: number; $lte?: number }
    // 'filters.maxRate'?: { $gte?: number; $lte?: number }
  }[]
  'filters.maxRate'?: { $gte?: number }
  'filters.minRate'?: { $lte?: number }

  'filters.maxRateHistoric'?: { $gte?: number }
  'filters.minRateHistoric'?: { $lte?: number }
}

type MapFiltersLeaseAvailableSfFind = {
  $or?: {
    $and?: {
      'filters.minSf'?: { $gte?: number; $lte?: number }
      'filters.maxSf'?: { $gte?: number; $lte?: number }

      'filters.minSfHistoric'?: { $gte?: number; $lte?: number }
      'filters.maxSfHistoric'?: { $gte?: number; $lte?: number }
    }[]
    // 'filters.minSf'?: { $gte?: number; $lte?: number }
    // 'filters.maxSf'?: { $gte?: number; $lte?: number }
  }[]
  'filters.maxSf'?: { $gte?: number }
  'filters.minSf'?: { $lte?: number }

  'filters.maxSfHistoric'?: { $gte?: number }
  'filters.minSfHistoric'?: { $lte?: number }
}

type MapFiltersLeaseFind = {
  // lease, saleLease
  type: { $in: string[] }
  $and?: (MapFiltersLeaseRateFind | MapFiltersLeaseAvailableSfFind)[]
}

type MapFiltersTypesFind =
  | {
      $or: (MapFiltersSaleFind | MapFiltersLeaseFind)[]
    }
  | {
      $or?: {
        $and?: {
          'filters.minAcres'?: { $gte?: number; $lte?: number }
          'filters.maxAcres'?: { $gte?: number; $lte?: number }
        }[]
        // 'filters.minAcres'?: { $gte?: number; $lte?: number }
        // 'filters.maxAcres'?: { $gte?: number; $lte?: number }
      }[]
      'filters.maxAcres'?: { $gte?: number }
      'filters.minAcres'?: { $lte?: number }
    }
  | {
      'filters.buildingSf'?: { $gte?: number; $lte?: number }
      // Sort
      'filters.price'?: { $ne: null }
      'filters.capRate'?: { $ne: null }
      'filters.acres'?: { $ne: null }
      'filters.bsf'?: { $ne: null }
      'filters.minRate'?: { $ne: null }
      // 'filters.maxRate'?: { $ne: null }
      'filters.minSf'?: { $ne: null }
      // { 'filters.maxSf': { $ne: null } }?,
    }

type MapFiltersFind = {
  $text?: { $search: string; $caseSensitive: boolean }
  status?: { $in: string[] }
  'property.postalCode'?: { $in: string[] }
  'property.city'?: { $in: string[] }
  'property.county'?: { $in: string[] }
  propertyTypes?: { $in: string[] }
  propertySubtypes?: { $in: string[] }
  'meta.submarket'?: { $in: string[] }
  'meta.zoning'?: { $regex: string }
  'company.tid'?: { $in: string[] }
  'brokers.tid'?: { $in: string[] }

  activeDate?: { $gte: string } // Change to range range
  offMarketDate?: { $gte?: string; $ne?: null } // Change to range // Use for sort too

  // For sort
  'filters.price'?: { $ne: null }
  'filters.maxRate'?: { $ne: null }
  'filters.minRate'?: { $ne: null }
  'filters.maxSf'?: { $ne: null }
  'filters.minSf'?: { $ne: null }
  'filters.maxAcres'?: { $ne: null }
  'filters.minAcres'?: { $ne: null }

  $and: MapFiltersTypesFind[]
}

function getSaleLeaseFilters(filters: MapFiltersType) {
  const $or = []
  if (filters.types!.includes('sale')) {
    const sale: MapFiltersSaleFind = {
      type: { $in: ['sale', 'saleLease'] },
    }
    if ((filters.prices || []).length > 0) {
      sale['filters.price'] = getFindRange(
        filters.prices![0],
        filters.prices![1]
      )
    }
    if ((filters.caps || []).length > 0) {
      sale['filters.capRate'] = getFindRange(filters.caps![0], filters.caps![1])
    }
    $or.push(sale)
  }
  if (filters.types!.includes('lease')) {
    const $and = []
    const minRateKey = (filters.statuses || []).includes('leased')
      ? 'filters.minRateHistoric'
      : 'filters.minRate'
    const maxRateKey = (filters.statuses || []).includes('leased')
      ? 'filters.maxRateHistoric'
      : 'filters.maxRate'
    if ((filters.rates || []).length > 0) {
      const minRate = filters.rates![0]
      const maxRate = filters.rates![1]
      if (minRate && maxRate) {
        $and.push({
          $or: [
            // { [minRateKey]: { $gte: minRate, $lte: maxRate } },
            {
              $and: [
                { [minRateKey]: { $gte: minRate } },
                { [minRateKey]: { $lte: maxRate } },
              ],
            },
            {
              $and: [
                { [maxRateKey]: { $gte: minRate } },
                { [maxRateKey]: { $lte: maxRate } },
              ],
            },
            {
              $and: [
                { [minRateKey]: { $lte: minRate } },
                { [maxRateKey]: { $gte: maxRate } },
              ],
            },
          ],
        } as MapFiltersLeaseRateFind)
      } else if (minRate) {
        $and.push({ [maxRateKey]: { $gte: minRate } })
      } else if (maxRate) {
        $and.push({ [minRateKey]: { $lte: maxRate } })
      }
    }

    if ((filters.sf || []).length > 0) {
      const minSf = filters.sf![0]
      const maxSf = filters.sf![1]
      const minSfKey = (filters.statuses || []).includes('leased')
        ? 'filters.minSfHistoric'
        : 'filters.minSf'
      const maxSfKey = (filters.statuses || []).includes('leased')
        ? 'filters.maxSfHistoric'
        : 'filters.maxSf'
      if (minSf && maxSf) {
        $and.push({
          $or: [
            {
              // 'filters.minSf': { $gte: minSf, $lte: maxSf },
              $and: [
                { [minSfKey]: { $gte: minSf } },
                { [minSfKey]: { $lte: maxSf } },
              ],
            },
            {
              $and: [
                { [maxSfKey]: { $gte: minSf } },
                { [maxSfKey]: { $lte: maxSf } },
              ],
            },
            {
              $and: [
                { [minSfKey]: { $lte: minSf } },
                { [maxSfKey]: { $gte: maxSf } },
              ],
            },
          ],
        } as MapFiltersLeaseAvailableSfFind)
      } else if (minSf) {
        $and.push({
          [maxSfKey]: { $gte: minSf },
        } as MapFiltersLeaseRateFind)
      } else if (maxSf) {
        $and.push({
          [minSfKey]: { $lte: maxSf },
        } as MapFiltersLeaseRateFind)
      }
    }

    const lease: MapFiltersLeaseFind = {
      type: { $in: ['lease', 'saleLease'] },
      // $and: $and.length > 0 ? $and : undefined,
    }
    if ($and.length > 0) {
      lease.$and = $and
    }
    $or.push(lease)
  }
  return $or
}

function getAcresFind(filters: MapFiltersType) {
  if ((filters.acres || []).length) {
    const minAcres = filters.acres![0]
    const maxAcres = filters.acres![1]
    const minAcresKey = (filters.statuses || []).includes('leased')
      ? 'filters.minAcresHistoric'
      : 'filters.minAcres'
    const maxAcresKey = (filters.statuses || []).includes('leased')
      ? 'filters.maxAcresHistoric'
      : 'filters.maxAcres'
    if (minAcres && maxAcres) {
      return {
        $or: [
          {
            $and: [
              { [minAcresKey]: { $gte: minAcres } },
              { [minAcresKey]: { $lte: maxAcres } },
            ],
          },
          {
            $and: [
              { [maxAcresKey]: { $gte: minAcres } },
              { [maxAcresKey]: { $lte: maxAcres } },
            ],
          },
          {
            $and: [
              { [minAcresKey]: { $lte: minAcres } },
              { [maxAcresKey]: { $gte: maxAcres } },
            ],
          },
        ],
      }
    } else if (minAcres) {
      return { [maxAcresKey]: { $gte: minAcres } }
    } else if (maxAcres) {
      return { [minAcresKey]: { $lte: maxAcres } }
    }
  }
}

export function getFindFromFilters(filters: MapFiltersType) {
  // Others
  const $and: MapFiltersTypesFind[] = [
    { $or: getSaleLeaseFilters(filters) }, // lease and sale specific
  ]
  const acresFind = getAcresFind(filters)
  if (acresFind) {
    $and.push(acresFind)
  }
  if ((filters.bsf || []).length > 0) {
    $and.push({
      'filters.buildingSf': getFindRange(filters.bsf![0], filters.bsf![1]),
    })
  }

  const find: MapFiltersFind = {
    // type: { $in: [...filters.types!, 'saleLease'] },
    status: { $in: filters.statuses! },
    $and,
  }

  if (filters.search && filters.search.trim().length > 0) {
    find.$text = { $search: filters.search.trim(), $caseSensitive: false }
  }
  if ((filters.propertyTypes || []).length > 0) {
    find.propertyTypes = { $in: filters.propertyTypes! }
  }
  if ((filters.propertySubtypes || []).length > 0) {
    find.propertySubtypes = { $in: filters.propertySubtypes! }
  }

  if ((filters.submarkets || []).length > 0) {
    find['meta.submarket'] = { $in: filters.submarkets! }
  }

  if ((filters.postalCodes || []).length > 0) {
    find['property.postalCode'] = {
      $in: filters.postalCodes!,
    }
  }
  if ((filters.cities || []).length > 0) {
    find['property.city'] = { $in: filters.cities! }
  }
  if ((filters.counties || []).length > 0) {
    find['property.county'] = { $in: filters.counties! }
  }
  if ((filters.zoning || []).length > 0) {
    find['meta.zoning'] = { $regex: filters.zoning!.join('|') }
  }
  if ((filters.companies || []).length > 0) {
    find['company.tid'] = { $in: filters.companies! }
  }
  if ((filters.brokers || []).length > 0) {
    find['brokers.tid'] = { $in: filters.brokers! }
  }

  if (filters.sort) {
    if (filters.sort.includes('price')) {
      find['filters.price'] = { $ne: null }
    } else if (filters.sort.includes('rateD')) {
      find['filters.maxRate'] = { $ne: null }
    } else if (filters.sort.includes('rateA')) {
      find['filters.minRate'] = { $ne: null }
    } else if (filters.sort === 'availableSfD') {
      find['filters.maxSf'] = { $ne: null }
    } else if (filters.sort === 'availableSfA') {
      find['filters.minSf'] = { $ne: null }
    } else if (filters.sort.includes('acresD')) {
      find['filters.maxAcres'] = { $ne: null }
    } else if (filters.sort.includes('acresA')) {
      find['filters.minAcres'] = { $ne: null }
    } else if (filters.sort.includes('offMarket')) {
      find.offMarketDate = { $ne: null }
    }
  }

  if (filters.created) {
    find['activeDate'] = { $gte: filters.created! }
  }
  // offMarketDate below to override the sort above
  if (filters.transacted) {
    find['offMarketDate'] = { $gte: filters.transacted! }
  }

  return find
}

export const aFiltersFind = atom((get) => {
  const filters = get(aMapFilters)
  return getFindFromFilters(filters)
})

export const aFiltersSort = atom((get) => {
  const filters = get(aMapFilters)
  return getSortFromFilters(filters)
})

export function getSortFromFilters(filters: MapFiltersType) {
  let sort: Sort = { modified: -1 }
  const sortKeys: { [key: string]: string } = {
    modifiedD: 'modified',
    createdD: 'activeDate',
    offMarketD: 'offMarketDate',
    availableSfD: 'filters.maxSf',
    availableSfA: 'filters.minSf',
    priceD: 'filters.price',
    priceA: 'filters.price',
    rateD: 'filters.maxRate',
    rateA: 'filters.minRate',
    acresD: 'filters.maxAcres',
    acresA: 'filters.minAcres',
  }
  if (filters.sort) {
    const direction = filters.sort.slice(filters.sort.length - 1)
    if (
      typeof sortKeys[filters.sort] !== 'undefined' &&
      (direction === 'D' || direction === 'A')
    ) {
      sort = { [sortKeys[filters.sort]]: direction === 'D' ? -1 : 1 }
    }
  }
  return sort
}
